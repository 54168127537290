.sensor_section h4{
   padding: 25px 0px;

}
.sensor_section h3{
   font-size: 18px;
   padding: 10px;
}
.sensor_content {
   padding-top: 30px;
}
.sensor_content h6{
   padding-bottom: 13px;
}
.table_header{
   text-align: center;
   background: #F5F1E8;
   height: 54px;
}
.table_header h4{
   font-weight: 600;
   font-size: 18px;
   color: #333333;
   padding-top: 15px;
   
}
.sensor_section table{
   background-color: #fff;
   border-radius: 8px;
   padding: 10px;

}
.sensor_section td{
   width: 250px;
}