.performance_wrap {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}
.performance-row {
	z-index: 10;
	font-family: Roboto;
	width: 100%;
	padding: 1rem;
	margin-top: 3rem;
}
/* .performance-heading-row {
	padding: 0px 0 0 16px;
} */
svg.recharts-surface {
    width: 100%;
}
.recharts-wrapper {
width: 100% !important;
}
svg.recharts-surface {
    width: 100%;
}
.recharts-wrapper {
width: 100% !important;
}
.performance-heading-col {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 45px;
	
}
.today-action-report {
	padding: 15px;
    border: 1px solid #FF8800;
    border-radius: 4px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    font-weight: 500;
    margin-left: 2rem;
    transition: all .3s ease-in;
	background: #FF8800;
	border: 1px solid #FF8800;
}

.today-action-report_dissabled {
	padding: 15px;
    border: 1px solid #FF8800;
    border-radius: 4px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    font-weight: 500;
    margin-left: 2rem;
    transition: all .3s ease-in;
	background: #c9b9a6;
	border: 1px solid #FF8800;
}
.cleaning-img {
    margin: 20px 0px 20px 0px;
}
.cleaning-heading-col {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 45px;
	padding-left: 10px;
	padding-right: 26px;
}
.cleaning-mr {
  margin-bottom: 50px;
}
.performance-heading-p {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.0025em;
	color: #2D232E;
	margin-top: 10px;
}
.performance-heading-p span {
	color: #7d7d7d;
}
.performance-heading-h2 {
	font-weight: 500;
	font-size: 36px;
	line-height: 44px;
	letter-spacing: 0.0025em;
	padding-bottom: .85rem;
}
.performance-row-link {
	cursor: pointer !important;
    text-decoration: none;
}
.performance-row-btn {
    padding: .65rem .65rem;
    background: #FF8800;
    border-radius: 4px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}
@media (max-width: 767px){
	.performance-heading-col {
		display: block;}
		.performance-heading-h2 {
			font-size: 20px;
			line-height: 30px;}
	.today-action-report{
		margin-left: 0rem
	}
	.performance-row-link {
		display: block;
		margin: 16px 0px 41px;
	}
}