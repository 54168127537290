* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f2f2 !important;
}
::selection {
  background: #ffd072;
}
*::-webkit-scrollbar {
    width: .5rem;
}
*::-webkit-scrollbar-track {
    background: #f2f2f2;
}
*::-webkit-scrollbar-thumb {
    background: #FF8800;
}
/* input[type=date]::-webkit-datetime-edit-text {
  -webkit-appearance: none;
  display: none;
}
input[type=date]::-webkit-datetime-edit-month-field{
  -webkit-appearance: none;
  display: none;
}
input[type=date]::-webkit-datetime-edit-day-field {
  -webkit-appearance: none;
  display: none;
}
input[type=date]::-webkit-datetime-edit-year-field {
  -webkit-appearance: none;
  display: none;
} */