.plants-cards {
    margin-bottom: 12px;
}

.plants-cards .card-body p {
    margin-bottom: 0rem !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: rgba(45, 35, 46, 0.65);
}

.plants-cards .card-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
}
.action-connecter {
    margin-top: 7rem;
    background: linear-gradient(90deg, rgba(255, 153, 0, 0.2) 0%, rgba(255, 153, 0, 0) 100%);
    border-radius: 8px;
    padding-top: 15px;
}
.action-connecter p {
    padding: 0px 0px 12px 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 16px;
    letter-spacing: 0.0025em;
    color: #333333;
    padding-top: 10px;
}