.sensor-p1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 8px;
    line-height: 150%;
    color: rgba(45, 35, 46, 0.65);
}

.t-left {
    text-align: end;
}

.mb {
    margin-bottom: 15px;
}

.sensor-reading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 8px;
    line-height: 150%;
    color: #000000 !important;
}

.row-bg {
    background: #EEEEEE;
    padding: 20px;
}

.required-bg {
    background: #EEEEEE;
    padding: 20px;
    margin-top: 30px;
}

.required-bg h6 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #000000;
}

.required-bg p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: rgba(45, 35, 46, 0.65);
}

.required-bg ul li::before {
    content: "\2022";
    color: #FF8800;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.required-bg ul {
    list-style: none;
}

.action-connecter .btn-secondary {
    background: #FFFFFF;
    border: 1px solid #FF8800;
    border-radius: 8px;
    color: #BDBDBD;
}

.action-connecter .show > .btn-secondary.dropdown-toggle {
    background: #FFFFFF;
    border: 1px solid #FF8800;
    border-radius: 8px;
    color: #BDBDBD;   
}

.action-connecter .dropdown-item:hover {
    background: #FF8800;
}