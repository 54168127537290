nav {
	height: 90px;
	background: #fff;
	z-index: 1000 !important;
	box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.2);
}
nav .navbar-container {
	width: 95%;
    margin: auto;
	display: flex;
	justify-content: space-between;
}
nav .navbar-logo {
	width: 235.46px;
} 
nav .nav-right-content {
	display: flex;
	align-items: center;
}
nav span:not(:last-child) {
	display: flex;
	align-items: center;
	padding-right: 2.95rem;
}
nav span .nav-icon {
	font-size: 1.5rem !important;
	cursor: pointer;
}
nav span .nav-icon.calendar {
	margin-right: .85rem;
}
nav span .calender-date {
	font-size: 1.25rem;
	color: #7f7f7f;
	font-weight: 500;
}
nav .profile-dp .profile-dp-img {
	padding-right: .55rem;
	width: 40px;
}
.profile-dp	{
	font-size: 20px;
	display: flex;
}
.personal-logo {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background-color: #ff8800;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	background-position: center;
	background-repeat: no-repeat;
	text-transform: uppercase;
	margin-right: 10px;
}
.profile-dp a {
	text-decoration: none;
	color: black;
	font-weight: 600;
}
nav .profile-dp .profile-dp-icon {
	cursor: pointer;
}
.sticky-header {
	position: fixed;
    top: 0px;
    width: 100%;
    height: 85px;
}
@media (max-width: 767px){
	nav .navbar-logo {
		width: 152px;
	}
	.calender-date{
		display: none;
	}
	nav span:not(:last-child) {
		display: flex;
		align-items: center;
		padding-right: 0.1rem;
	}
	nav span .nav-icon.calendar{
		display: none;
	}
	.nav-left-content{
		position: relative;
		left: 30px;
	}
}


.dropdown {
	position: relative;
	display: inline-block;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	padding: 12px 0px 6px 0px;
	z-index: 1;
	top: 55px;
	right: 30px;
	width: 200px;
}

.dropdown:hover .dropdown-content {
	display: block;
}

.dropdown-item {
	margin-bottom: 6px;
	background: #fff;
	padding: 8px 16px 8px 16px;
	font-weight: 400;
	font-size: 14px;
	color: #000;
	cursor: pointer;
}