.not_found_wrap {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.not_found_text {
    font-weight: 500;
    font-size: 36px;
    color: #333333;
}
.not_found_img {
    width: 100%;
    height: 70%;
    object-fit: contain;
}